<template>
  <div class="home">
    <div class="top" style="padding-left: 40px;padding-right: 40px;">{{ hotelbaseInfo.sitename||hotelInfo.name}}</div >
    <div class="topbanner"></div>
    <div class="homeContent">
     <div class="indexContent" v-show="tabactive==1">
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':openbaseInfo==true }" >
            <span class="tit"  @click="openSth('openbaseInfo')">酒店基本信息</span>
          <ul class="baseInfo" v-if="openbaseInfo==true">
            <li><span class="title">酒店全称：</span> <span>{{hotelbaseInfo.sitename||hotelInfo.name}}</span></li>
            <li><span class="title">法人姓名：</span> <span>{{hotelbaseInfo.faRen||hotelInfo.juridicalPerson}}</span></li>
            <li><span class="title">经营地址：</span> <span>{{hotelbaseInfo.address||hotelInfo.address }}</span></li>
            <li><span class="title">社会信用代码：</span> <span>{{hotelbaseInfo.creditCode||hotelInfo.CreditCode}}</span></li>
            <li><span class="title">卫生许可证号：</span> <span>{{hotelbaseInfo.healthLicense||hotelInfo.SanitaryPermits}}</span></li>
            <li><span class="title">许可证有效期限：</span> <span>{{hotelbaseInfo.licenseDate||hotelInfo.duration }}</span></li>
            <li><span class="title">量化等级：</span> <span>{{ hotelbaseInfo.grade||hotelInfo.level }}</span></li>
          </ul>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'activeHealthSystem':openHealthSystem==true }" >
            <span class="tit"  @click="openSth('openHealthSystem')">酒店卫生制度</span>
            <div class="HealthSystem"  v-if="openHealthSystem==true">
              <div class="title"><span>时间</span><span>内容</span><span>详情</span></div>
              <div class="tablebox">
              <div class="table" @click="gopdf('/lchygienewater','glhygienewater','/gthygienewater','/hahygienewater') ">
                <span><i>2023-07</i> <i class="itit">二次供水设施卫生管理制度</i> <i class="sj"></i></span>
              </div>
              <div class="table" @click="gopdf('/lchygienepubdevice','/glhygienepubdevice','/gthygienepubdevice','/hahygienepubdevice') ">
                <span><i>2023-07</i> <i class="itit">卫生设施设备维护保养制度</i> <i class="sj"></i></span>
              </div>   
              <div class="table" @click="gopdf('/lchygieneair','/glhygieneair','/gthygieneair','/hahygieneair') ">
                <span><i>2023-07</i> <i class="itit">公共场所空调系统卫生管理制度</i> <i class="sj"></i></span>
              </div>  
              <div class="table" @click="gopdf('/lchygienehealth','/glhygienehealth','/gthygienehealth','/hahygienehealth') ">
                <span><i>2023-07</i> <i class="itit">{{hotelId=='0164'?'从业人员健康体检、卫生培训和个人卫生制度':'从业人员健康体检制度'}}</i> <i class="sj"></i></span>
              </div>  
              <div class="table" @click="gopdf('/lchygienesmoke','/glhygienesmoke','/gthygienesmoke','/hahygienesmoke') ">
                <span><i>2023-07</i> <i class="itit">公共场所禁烟管理制度</i> <i class="sj"></i></span>
              </div>  
              <div class="table" v-if="hotelId!=='0164'" @click="gopdf('/lchygieneclean','','/gthygieneclean','/hahygieneclean') ">
                <span><i>2023-07</i> <i class="itit">公共场所卫生管理制度</i> <i class="sj"></i></span>
              </div> 
              <div class="table" v-if="hotelId!=='0164'"  @click="gopdf('/lchygienecup','','/gthygienecup','/hahygienecup') ">
                <span><i>2023-07</i> <i class="itit">公共用品用具清洗消毒制度</i> <i class="sj"></i></span>
              </div>        
              <div class="table"  v-if="hotelId!=='0163'"  @click="gopdf('/lchygienepool','/glhygienepool','','/hahygienepool') ">
                <span><i>2023-07</i> <i class="itit">游泳场所卫生制度</i> <i class="sj"></i></span>
              </div>        
              <div class="table" @click="gopdf('/lchygienebuy','/glhygienebuy','/gthygienebuy','/hahygienebuy') ">
                <span><i>2023-07</i> <i class="itit">{{hotelId=='0164'?'卫生用品索证、验收制度':'卫生用品索证制度'}}</i> <i class="sj"></i></span>
              </div>     
              <div class="table" @click="gopdf('/lchygienepubhealth','/glhygienepubhealth','/gthygienepubhealth','/hahygienepubhealth') ">
                <span><i>2023-07</i> <i class="itit">公共场所预防控制传染性疾病传播及健康危害事故应急处理工作预案</i> <i class="sj"></i></span>
              </div>      

              </div>
                     
            </div>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':openDetectionRecords==true }" >
            <span class="tit"  @click="openSth('openDetectionRecords')">酒店检测记录</span>
            <div class="DetectionRecords"  v-if="openDetectionRecords==true">
              <div class="title"><span style="margin-left: 13px;">时间</span><span>内容</span><span style="margin-right: 10px;">详情</span></div>
                <div class="tablebox2" >
                 <div class="table" @click="gopdf('/lctestair','/gltestair','/gttestwgs','/hatestair') ">
                  <span><i style="margin-left: 10px;">2023-07</i> <i class="dtit">{{hotelId=='0165'?'集中空调检测记录':hotelId=='0164'?'集中空调通风系统检测报告':hotelId=='0163'?'管网末梢水报告':'空调系统检测报告'}}</i> <i class="sj"></i></span>
                 </div>
                 <div class="table" @click="gopdf('/lctestwater','/gltestwater','/gttestwater','/hatestwater') ">
                  <span><i style="margin-left: 10px;">2023-07</i> <i class="dtit">{{hotelId=='0165'?'二次供水检测记录':hotelId=='0164'?'二次供水检测报告':hotelId=='0163'?'二次供水检测报告':'管网末梢水检测报告'}}</i> <i class="sj"></i></span>
                 </div>
                 <div class="table" @click="gopdf('/lctestpub','/gltestpub','/gttestpub','hatestpub') ">
                  <span><i style="margin-left: 10px;">2023-07</i> <i class="dtit">{{hotelId=='0165'?'公用品，微小空气检测报告':hotelId=='0164'?'室内环境、顾客用品用具检测报告':hotelId=='0163'?'室内环境、顾客用品用具检测报告':'室内环境、顾客用品用具检测报告'}}</i> <i class="sj"></i></span>
                 </div>
                 <div class="table" @click="gopdf('','gltestwgs','','') " v-if="hotelId=='0164'">
                  <span><i style="margin-left: 10px;">2023-07</i> <i class="dtit">管网末梢水检测报告</i> <i class="sj"></i></span>
                 </div>
                 <div class="table" @click="gopdf('','gltestpool','','') " v-if="hotelId=='0164'">
                  <span><i style="margin-left: 10px;">2023-07</i> <i class="dtit">游泳池水检测报告</i> <i class="sj"></i></span>
                 </div>
              </div>
            </div>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':openEmployeeInfo==true }"  >
            <span class="tit" @click="openSth('openEmployeeInfo')">员工体检信息</span>
            <div class="EmployeeInfo"  v-if="openEmployeeInfo==true">
              <div class="EmployeeInfotop">
                <div class="box"><span class="num">{{ healthrecord.epNum||'' }}</span><span class="title">从业人员数量</span></div>
                <div class="box"><span class="num">{{ healthrecord.recordNum||"" }}</span><span class="title">健康证获取数量</span></div>
                <div class="box"><span class="num">{{ healthrecord.rate||"" }}</span><span class="title">健康证获取率</span></div>
              </div>
              <div class="table">
                <div class="item">
            <el-table :data="tableData" border height="120" style="font-size: 12px;">
              <el-table-column prop="name" label="姓名" width="40" >
              </el-table-column>
              <el-table-column prop="ep" label="岗位" width="70">
              </el-table-column>
              <el-table-column prop="number" label="健康证号" width="120">
              </el-table-column>
              <el-table-column prop="time" label="发证时间" >
              </el-table-column>
            </el-table>
          </div>

              </div>
              
            </div>
         </div>
        </div>
     </div>
     <div class="roomContent" v-show="tabactive==2">
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':openbcInfo==true }" >
            <span class="tit"  @click="openroomSth('openbcInfo')">布草更换智能监管</span>
          <div class="bcInfo" v-if="openbcInfo==true">
            <div class="bctop"><img src="~@/assets/nb/bucao.png" alt=""></div>
            <div class="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;将RFID标签熨烫在酒店布草上，通过RFID读卡器记录布草的流转状态，主要是脏布草的送出、干净布草的送回。布草保洁，以及干净布草在房间内的铺设。酒店掌握布草的全流程状态，并可以向住客开放，提高入住满意度。酒店可以减少布草的人工清点，节约人力成本。同时酒店可以对布草的寿命做到精确管控。</div>
          </div>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':opencupInfo==true }" >
            <span class="tit"  @click="openroomSth('opencupInfo')">杯具清洗智能监管 </span>
            <div class="cupInfo"  v-if="opencupInfo==true">
            <div class="cuptop"><img src="~@/assets/nb/cup.png" alt=""></div>
            <div class="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在酒店杯具消毒间安装摄像头，自动采集保洁人员的工作记录。同时无线智能插座监控消毒柜的工作状态。系统平台对保洁人员的工作记录和消毒柜的工作记录进行分析，得出此洗消间是否进行了合规洗消的结论。</div>
            </div>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'active':openjjInfo==true }" >
            <span class="tit"  @click="openroomSth('openjjInfo')">洁具清洗智能监管</span>
            <div class="jjInfo"  v-if="openjjInfo==true">
              <div class="jjtop"><img src="~@/assets/nb/jieju.png" alt=""></div>
             <div class="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;将RFID标签贴在马桶、台盆和浴缸等洁具上，同时也贴在各自的抹布上。打扫的时候佩戴手环式读卡器。读卡器能同时读到洁具上的标签和抹布上的标签，设备能判断抹布和洁具是否匹配，并出相应的提醒，同时将打扫记录上报到平台。</div>
            </div>
         </div>
        </div>
        <div class="hotelTit"> 
          <div class="titleBar" :class="{ 'activeroomClearn':openroomInfo==true }"  >
            <span class="tit" @click="openroomSth('openroomInfo')">客房保洁详情查询</span>
            <div class="roomInfo"  v-if="openroomInfo==true">
              <div class="search">
                <div class="left">
                 <span></span>
                 <input type="text" placeholder="请输入房间号查询" v-model="roomId" > 
                </div>
                <div class="right" @click="getRoomInfo">
                  查询
                </div>
              </div>
              <div class="item">
                <p><i></i>保洁信息</p>
                <div class="clean">您现在入住的房间为<span style="color:orange">{{roomId}}</span>房间，最近一次清扫保洁时间为：{{ lastTime }}</div>
              </div>

              <div class="item">
                 <p><i></i>布草更换</p>
                 <el-table :data="roomtableData" border height="90">
                   <el-table-column prop="name" label="种类" width="40">
                     <template slot-scope="{row}">
                       <span v-if="row.name.substring(0, 2) === '56'">枕套</span>
                       <span v-if="row.name.substring(0, 2) === '67'">床单</span>
                       <span v-if="row.name.substring(0, 2) === '78'">被套</span>
                     </template>
                   </el-table-column>
                   <el-table-column prop="name" label="布草ID" width="100">
                   </el-table-column>
                   <el-table-column prop="roomCentralizationTime" label="更换时间">
                     <template slot-scope="{row}">
                       {{row.roomCentralizationTime?$moment(row.roomCentralizationTime).format('MM-DD HH:mm'):'-'}}
                     </template>
                   </el-table-column>
                 </el-table>
              </div>

              <div class="item" >
                <p><i></i>杯具消毒</p>
                <div class="clean" v-if="logStoolDishclothTime">杯具：<span style="color:#11ab23;margin-right:10px">已消毒</span>消毒时间：{{logStoolDishclothTime?'2023-09-12 10:45:21':''}}</div>
                <div class="clean" v-else>暂无</div>
              </div>
              <div class="item" >
                <p><i></i>洁具清洗</p>
                <div class="clean" v-if="logStoolDishclothTime">洁具：<span style="color:#11ab23;margin-right:10px">已清洁</span>清洁时间：{{logStoolDishclothTime}}</div>
                <div class="clean" v-else>暂无</div>
              </div>
            </div>
         </div>
        </div>
     </div>
 
    </div>
    <div class="tabbar">
      <div class="tab" @click="tabactive=1"> <span class="iconhome" :class="{ 'iconhomeactive':tabactive==1 }"></span> <span class="tabname" :class="{ 'tabnamehome':tabactive==1 }">首页</span></div>
      <div class="tab" @click="tabactive=2"> <span class="iconroom" :class="{ 'iconroomactive':tabactive==2 }"></span> <span class="tabname" :class="{ 'tabnameroom':tabactive==2 }">客房</span></div> 
      <div class="tab" @click="toother" v-if="hotelId=='0165'||hotelId=='0164'"> <span class="iconpool"></span> <span class="tabname" :class="{ 'tabnamepool':tabactive==3 }">泳池</span></div>
    </div>
    <div class="bottom">
     </div>
  </div>
</template>
<script>
import { getData,getHotelInfo } from '../../api';
export default {
  data(){
    return{
      hotelbaseInfo:{},
      tabactive:1,
      hotelId:'0165',
      openbaseInfo:false,
      openHealthSystem:false,
      openDetectionRecords:false,
      openEmployeeInfo:false,
      openbcInfo:false,
      opencupInfo:false,
      openjjInfo:false,
      openroomInfo:false,
      lcData:[
        {
          name:'曾松',
          ep:'楼层经理',
          number:'1102A6089',
          time:"2023-06"
        },
        {
          name:'王星',
          ep:'楼层主管',
          number:'1102A0520',
          time:"2023-03"
        },
         {
          name:'种邱平',
          ep:'楼层主管',
          number:'1102A9993',
          time:"2023-09"
        }
        ,
         {
          name:'班绍涵',
          ep:'实习生',
          number:'1102B4334',
          time:"2023-05"
        },
         {
          name:'晏雨琪',
          ep:'实习生',
          number:'1102B4332',
          time:"2023-05"
        },
         {
          name:'董广荣',
          ep:'楼层服务员',
          number:'1102A230330049',
          time:"2023-05"
        },
         {
          name:'董广芹',
          ep:'楼层服务员',
          number:'1102A230330048',
          time:"2023-05"
        },
         {
          name:'杨正丽',
          ep:'楼层服务员',
          number:'1102A230330052',
          time:"2023-03"
        },
         {
          name:'钱和林',
          ep:'楼层服务员',
          number:'1102A6252',
          time:"2023-07"
        },
         {
          name:'沈佳平',
          ep:'楼层服务员',
          number:'1102A6251',
          time:"2023-07"
        },
         {
          name:'江建明',
          ep:'楼层服务员',
          number:'1102A230904470',
          time:"2023-09"
        },
         {
          name:'庄晓东',
          ep:'楼层服务员',
          number:'1102A230904477',
          time:"2023-09"
        },
         {
          name:'苑永周',
          ep:'楼层服务员',
          number:'1201A21120911106',
          time:"2021-12"
        },
         {
          name:'秦记鱼',
          ep:'楼层服务员',
          number:'1201A21120711081',
          time:"2021-12"
        },
         {
          name:'刘红霞',
          ep:'楼层服务员',
          number:'1102A1028',
          time:"2023-03"
        },
         {
          name:'谢长江',
          ep:'楼层服务员',
          number:'1102A2275',
          time:"2023-04"
        },
        {
          name:'全玉华',
          ep:'楼层服务员',
          number:'1102A2276',
          time:"2023-04"
        }, {
          name:'刘猛',
          ep:'楼层服务员',
          number:'1102A5650',
          time:"2023-06"
        }, {
          name:'谢春玲',
          ep:'楼层服务员',
          number:'1102A5649',
          time:"2023-06"
        },{
          name:'崔浩然',
          ep:'楼层服务员',
          number:'1102A9740',
          time:"2023-09"
        },{
          name:'颜振春',
          ep:'洗衣房经理',
          number:'1102A6332',
          time:"2023-07"
        },
        
      ],
      gtData:[
      {
          name:'李敏',
          ep:'房务总监',
          number:'1102A230911583',
          time:"2023-09"
        },  {
          name:'谢郑洲',
          ep:'PA主管',
          number:'1102A221021152',
          time:"2022-10"
        },  {
          name:'李艳玲',
          ep:'楼层主管',
          number:'1102A230801442',
          time:"2023-08"
        }, {
          name:'陈昌恒',
          ep:'楼层主管',
          number:' 1102A230802430',
          time:"2023-08"
        }, {
          name:'林雪芬',
          ep:'楼层服务员',
          number:'1102A230728618',
          time:"2023-07"
        }, {
          name:'叶珍芝',
          ep:'楼层见习主管',
          number:'1102A230801443',
          time:"2023-08"
        }, {
          name:'蔡芬芬',
          ep:'楼层服务员',
          number:'1102A221021157',
          time:"2022-10"
        },{
          name:'朱再梅',
          ep:'楼层服务员',
          number:'1102A230831281',
          time:"2022-09"
        },{
          name:'周亚琴',
          ep:'布草员',
          number:'1102A221021176',
          time:"2022-10"
        },{
          name:'刘绍梅',
          ep:'布草员',
          number:'1102A221021174',
          time:"2022-10"
        },{
          name:'陈红舞',
          ep:'楼层服务员',
          number:'1102A230802424',
          time:"2023-08"
        },{
          name:'陈强',
          ep:'首席文化体验官',
          number:'1102B230731493',
          time:"2023-08"
        },{
          name:'兰静洁',
          ep:'前台主管',
          number:'1102A230801479',
          time:"2023-08"
        },{
          name:'陈巧琳',
          ep:'高级文化体验师',
          number:'1102A230724844',
          time:"2023-07"
        },{
          name:'桂腾伟',
          ep:'前台接待员',
          number:'1102A230802392',
          time:"2023-08"
        },{
          name:'刘梦菲',
          ep:'前台接待员',
          number:'1102A230817630',
          time:"2023-08"
        },{
          name:'施桂芬',
          ep:'礼宾员',
          number:'1102A230802390',
          time:"2023-08"
        },{
          name:'黄海云',
          ep:'礼宾员',
          number:'1102A7602',
          time:"2023-07"
        },{
          name:'徐铭骏',
          ep:'礼宾员',
          number:'1102A230801511',
          time:"2023-08"
        },{
          name:'江林杰',
          ep:'礼宾员',
          number:'1102A230801510',
          time:"2023-08"
        },{
          name:'徐铭骏',
          ep:'礼宾员',
          number:'1102A230801511',
          time:"2023-08"
        },{
          name:'王颖超',
          ep:'礼宾员',
          number:'1102A230504295',
          time:"2023-05"
        },{
          name:'励玲燕',
          ep:'康体员工',
          number:'1102A220907487',
          time:"2022-09"
        },{
          name:'张思佳',
          ep:'前台接待员',
          number:'1102A230801481',
          time:"2023-08"
        },{
          name:'张子彤',
          ep:'前台接待员',
          number:'1102A230802395',
          time:"2023-08"
        },{
          name:'孔盈盈',
          ep:'前台接待员',
          number:'1102A230801485',
          time:"2023-08"
        },{
          name:'支媛',
          ep:'文化体验师',
          number:'1102A230801445',
          time:"2023-08"
        },{
          name:'袁韦艺',
          ep:'文化体验师',
          number:'1102A230802394',
          time:"2023-08"
        },{
          name:'郑余婷',
          ep:'文化体验师',
          number:'1102A230801474',
          time:"2023-08"
        },{
          name:'陈悦',
          ep:'茶艺师',
          number:'1102A230801466',
          time:"2023-08"
        },{
          name:'代龙飞',
          ep:'礼宾员',
          number:'1102A230801446',
          time:"2023-08"
        },{
          name:'何菊兰',
          ep:'房务PA',
          number:'1102A230823457',
          time:"2023-08"
        },{
          name:'张影',
          ep:'房务PA',
          number:'1102A230830303',
          time:"2023-08"
        },{
          name:'卢庆荣',
          ep:'房务PA',
          number:'1102A230904441',
          time:"2023-09"
        },{
          name:'俞敏亚',
          ep:'房务PA',
          number:'1102B230824382',
          time:"2023-08"
        },{
          name:'李军',
          ep:'房务PA',
          number:'1102A230824321',
          time:"2023-08"
        },{
          name:'徐方明',
          ep:'房务PA',
          number:'1102A221026639',
          time:"2022-10"
        },{
          name:'李伊犁',
          ep:'房务PA',
          number:'1102A230511082',
          time:"2023-05"
        },{
          name:'杨肖波',
          ep:'房务楼层',
          number:'1102A230904510',
          time:"2023-09"
        },{
          name:'蔡小红',
          ep:'房务楼层',
          number:'1102A221107911',
          time:"2022-11"
        },{
          name:'史花云',
          ep:'房务楼层',
          number:'1102A221107911',
          time:"2023-08"
        },{
          name:'温永娟',
          ep:'房务楼层',
          number:'1102A230506643',
          time:"2023-05"
        },{
          name:'孙志花',
          ep:'房务楼层',
          number:'1102A230728620',
          time:"2023-07"
        },{
          name:'王瑞明',
          ep:'房务楼层',
          number:'2501A210125001',
          time:"2021-01"
        },{
          name:'赵磊芳',
          ep:'房务楼层',
          number:'1102A230904473',
          time:"2023-09"
        },
      ],
      glData:[
      {
          name:'梅莉莉',
          ep:'主管',
          number:'102A230406008',
          time:"2023-04"
        },  {
          name:'黄敏华',
          ep:'楼层服务员',
          number:'1102A1945',
          time:"2023-04"
        }, {
          name:'顾亚辉',
          ep:'主管',
          number:'1102A230720751',
          time:"2023-07"
        }, {
          name:'吴金彩',
          ep:'主管',
          number:'0315A230904006',
          time:"2023-09"
        }, {
          name:'赵国荣',
          ep:'主管',
          number:'1102A230719568',
          time:"2023-07"
        }, {
          name:'童秋菊',
          ep:'主管',
          number:'1102A220930198',
          time:"2022-09"
        }, {
          name:'朱铁华',
          ep:'主管',
          number:'1102B220919946',
          time:"2022-09"
        }, {
          name:'包云飞',
          ep:'楼层服务员',
          number:'1102A221014002',
          time:"2022-10"
        }, {
          name:'姜敏飞',
          ep:'楼层服务员',
          number:'1102A220930204',
          time:"2022-09"
        },  {
          name:'许海娅',
          ep:'楼层服务员',
          number:'1102A230406016',
          time:"2023-04"
        },  {
          name:'林珍娅',
          ep:'楼层服务员',
          number:'1102A220930201',
          time:"2022-09"
        },  {
          name:'邵玲',
          ep:'楼层服务员',
          number:'1102A230202181',
          time:"2023-02"
        },  {
          name:'陈建英',
          ep:'楼层服务员',
          number:'1102A230413191',
          time:"2023-04"
        }, 

      ],
      haData:[
      {
          name:'林芬娣',
          ep:'主管',
          number:'1102A7328',
          time:"2023-07"
        },{
          name:'周素',
          ep:'保洁员',
          number:'1102A7024',
          time:"2023-07"
        },{
          name:'舒敏',
          ep:'保洁员',
          number:'1102A0152',
          time:"2023-09"
        },{
          name:'宋敏红',
          ep:'保洁员',
          number:'1102A2940',
          time:"2022-09"
        },{
          name:'李秀兰',
          ep:'保洁员',
          number:'1102A0151',
          time:"2023-09"
        },{
          name:'朱丹丹',
          ep:'保洁员',
          number:'1102A7370',
          time:"2023-07"
        },{
          name:'潘柳霞',
          ep:'保洁员',
          number:'1102A7329',
          time:"2023-07"
        },{
          name:'段赛芬',
          ep:'保洁员',
          number:'1102A8515',
          time:"2023-08"
        },{
          name:'张磊',
          ep:'保洁员',
          number:'1102A7074',
          time:"2023-07"
        },
      ],
      roomId:'',
      showRoomInfo:false,
      hotelMsg:{},
      roomtableData:[],
      lastTime:'',
      cupsDisinfectTime: '',
      logStoolDishclothTime: '',
    }
  },
  created(){
    this.init()
    this.keyboard()
    console.log(this.tabactive);
  },
  computed:{
    tableData(){
      if(this.hotelId=='0165'){
        return this.lcData
      }else if (this.hotelId=='0164') {
        return this.glData
      }else if (this.hotelId=='0163') {
        return this.gtData
      }else{
        return this.haData
      }
    },
    healthrecord(){
      if (this.hotelId=='0165') {
        return {epNum:21,recordNum:21,rate:"100%"}
      }else if (this.hotelId=='0164') {
        return {epNum:13,recordNum:13,rate:"100%"}
      }else if (this.hotelId=='0163') {
        return {epNum:45,recordNum:45,rate:"100%"}
      }else{
        return {epNum:9,recordNum:9,rate:"100%"}
      }
    },
    hotelInfo(){
      if (this.hotelId == "0165") {
        return {name:"宁波象山绿城喜来登酒店",juridicalPerson:'王军',address:'浙江省宁波市象山县亚帆路88号',CreditCode:'91330225MA7BGGE189',SanitaryPermits:'330225-000251',duration:'2022/06/22 - 2026/06/21',level:'C级'}
      }else if(this.hotelId == "0164"){
        return {name:"宁波半边山工人疗养院有限公司酒店管理分公司",juridicalPerson:'魏献通',address:'浙江省宁波市象山县石浦镇东海半边山旅游度假区麒麟南路99号',CreditCode:'91330225MA2813WM5B',SanitaryPermits:'330225010077',duration:'2021/03/27-2025/03/26',level:'A级'}
      }else if(this.hotelId == "0163"){
        return {name:"宁波半边山投资有限公司观堂酒店管理分公司",juridicalPerson:'张铁成',address:'浙江省宁波市象山县半边山风景区渔湾路102号',CreditCode:'91330225MA7L5WL663',SanitaryPermits:'330225-000348',duration:'2022/08/16-2026/08/15',level:'B级'}
      }else{
        return {name:" 象山黄金海岸大酒店有限公司",juridicalPerson:'林芳羽',address:'浙江省宁波市象山县松兰山度假区',CreditCode:'91330225725175801M',SanitaryPermits:'330225-000046',duration:'2023/06/10-2027/06/09',level:'/'}
        }
    }
  },
  methods:{
    keyboard () {

    document.body.addEventListener('focusin', () => {
      // 软键盘弹起事件
      this.flag = false
      // console.log(111)
    })
    document.body.addEventListener('focusout', () => {
      // 软键盘关闭事件
      this.flag = true
      // this.$router.go()
      // console.log(222)
    })
    },
    // 获取id
    init(){
      this.hotelId = this.getQueryVariable('hotel')
      this.getHotelbaseinfo()
    },
    // 截取地址字段
    getQueryVariable (variable) {
      if (window.location.search) {
        const arr = window.location.search.split("?")
        var vars = arr[1].split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return decodeURIComponent(pair[1]); }
        }
        return (false);
      }
    },
    // a页开关
    openSth(val){
      if (val=='openbaseInfo') {
        this.openbaseInfo =!this.openbaseInfo
        this.openHealthSystem=false
        this.openDetectionRecords=false
        this.openEmployeeInfo=false
      }else if(val=='openHealthSystem'){
       this.openHealthSystem=!this.openHealthSystem
       this.openbaseInfo=false
       this.openDetectionRecords=false
        this.openEmployeeInfo=false
      }else if(val=='openDetectionRecords'){
       this.openDetectionRecords=!this.openDetectionRecords
       this.openbaseInfo=false
       this.openHealthSystem=false
        this.openEmployeeInfo=false
      }else{
        this.openEmployeeInfo=!this.openEmployeeInfo
        this.openbaseInfo=false
       this.openHealthSystem=false
        this.openDetectionRecords=false
      }

      // this.open=!this.open
    },
    // b页开关
    openroomSth(val){
      if (val=='openbcInfo') {
        this.openbcInfo =!this.openbcInfo
        this.opencupInfo=false
        this.openjjInfo=false
        this.openroomInfo=false
      }else if(val=='opencupInfo'){
       this.opencupInfo=!this.opencupInfo
       this.openbcInfo=false
       this.openjjInfo=false
        this.openroomInfo=false
      }else if(val=='openjjInfo'){
       this.openjjInfo=!this.openjjInfo
       this.openbcInfo=false
       this.opencupInfo=false
        this.openroomInfo=false
      }else{
        this.openroomInfo=!this.openroomInfo
        this.openbcInfo=false
       this.opencupInfo=false
        this.openjjInfo=false
      }

      // this.open=!this.open
    },
    // 获取房间信息
    getRoomInfo(){
      if (this.roomId!=='') {
        console.log(this.roomId);
        console.log(this.hotelId);
        getData(this.hotelId, this.roomId).then(res=>{
          const { data } = res
        this.hotelMsg = data.hotel
        this.lastTime = data.lastTime ? this.$moment(data.lastTime).format('YYYY-MM-DD') : '暂无'
        this.roomtableData = data.logTextile
        if (data.cups && data.cups.length > 0 && data.cups[0].cupsDisinfectTime) {
          this.cupsDisinfectTime = data.cups[0].cupsDisinfectTime
        }
        this.logStoolDishclothTime = data.logStoolDishclothTime
        })
      
      }
    },
    // 获取酒店信息
    getHotelbaseinfo(){
      getHotelInfo(this.hotelId).then(res=>{
        console.log(res);
        if (res.code==0) {
          res.data.healthLicense = (res.data.healthLicense.split('第')[1]).split('号')[0]
          this.hotelbaseInfo = res.data
          console.log(this.hotelbaseInfo);
        }
      })
    },
    // 跳转泳池
    toother(){
      if (this.hotelId=='0165') {
        window.location.href='https://yycswsaq.wsjkw.zj.gov.cn/zjyycsh5/public/detail/818e0c4a-18a7-4eab-9f01-9a3086610781'
       }else {
        window.location.href='https://yycswsaq.wsjkw.zj.gov.cn/zjyycsh5/public/detail/22e890cc-36ea-49c3-8494-b68a1ce163c8'
       }
    },
    gopdf(a,b,c,d){
      console.log(a,b,c,d);
      if (this.hotelId=='0165') {
        localStorage.setItem('pdfName', a)
      }else if (this.hotelId=='0164') {
        localStorage.setItem('pdfName', b)
      }else if (this.hotelId=='0163') {
        localStorage.setItem('pdfName', c)
      }else{
        localStorage.setItem('pdfName', d)
      }
      this.$router.push('/pdf')
    }

  }
}
</script>

<style scoped lang="scss">
// *{
//   margin: 0 auto;
// }

.home{
  width: 100%;
  height: 100vh;
  background-color:#3088f7;
  background-size: 100% 100%;
  position: relative;
  padding-bottom: 150px;
  .top{
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      padding: 15px 0;
      padding-top: 30px;
    }
    .topbanner{
      width: 100%;
      height: 97px;
      background: url('~@/assets/nb/banner.png') no-repeat;
      background-size: 100%;
    }
 .homeContent{
  padding:0 20px ;
  background-color:#3087f7;
  .indexContent{
    width: 100%;
    background-color: #3087f7;
   .hotelTit{
    position: relative;
    margin-bottom: 15px;
    .titleBar{
    background: url('~@/assets/nb/title.png');
    background-size: 100%;
    height: 36px;
      width: 100%;
      .tit{
        display: block;
        width: 100%;
        font-size: 18px;
      font-weight: bold;
      color: #E5F6FF;
      text-align: center;
      text-shadow: 0px 2px 3px rgba(4,94,211,0.6);
      }
    }
    .activeHealthSystem{
      background: url('~@/assets/nb/hbg.png') no-repeat;
      background-size: 100%;
      width: 100%;
      height: 100%;
      .HealthSystem {
      padding: 30px 20px 12px 20px;
      .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span{
          padding: 0 15px;
            display: block;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #464B64;
        }
      }
      .tablebox{
        overflow: scroll;
        height: 210px;
      .table{
        margin-top: 10px;
        span{
          display: block;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background: linear-gradient(41deg,#b6d5ff, #e6f1ff);
          box-shadow: 0px 2px 4px 0px rgba(36,92,163,0.38);
          border-radius: 7px;
          i{
            margin-left: 10px;
            display: block;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #464B64;
            padding: 3px 0;
          }
          .itit{
            width: 50%;
          }
          
          
          .sj{
            width: 30px;
            height: 30px;
            background: url('~@/assets/nb/PDF.png') no-repeat;
            background-size: 100% ;
            background-position: 0 50%;
            position: relative;
            right: 15px;

            &::after{
              content: '';
              position: absolute;
              top: 50%;
              left: 100%;
              transform: translateY(-50%);
            display: inline-block;
            opacity: 0.5;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #135EFF;
            font-size: 0;
            line-height: 0;
            transform: rotate(90deg);
            }

            
          }
          
        }
      }
      }  
     
    }

    }
    .active{
      background: url('~@/assets/nb/activeTit.png') no-repeat;
      background-size: 100%;
      width: 100%;
      height: 120%;
      .baseInfo{
       padding: 15px 15px 5px 30px;
       font-size: 12px;
       font-weight: 400;
       color: #464B64;
        li{
          margin: 3px 0;
        }
      }
   


    .DetectionRecords {
      padding: 30px 20px 12px 20px;
      .title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        span{
          display: block;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #464B64;
        }
      }
      .tablebox2{
        overflow: scroll;
        height: 140px;
        .table{
        margin-top: 10px;
        span{
          display: block;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background: linear-gradient(41deg,#b6d5ff, #e6f1ff);
          box-shadow: 0px 2px 4px 0px rgba(36,92,163,0.38);
          border-radius: 7px;
          i{
            display: block;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #464B64;
            padding: 3px 0;
          }
          .dtit{
            width: 50%;
          }
          .sj{
            width: 30px;
            height: 30px;
            background: url('~@/assets/nb/PDF.png') no-repeat;
            background-size: 100% ;
            background-position: 0 50%;
            position: relative;
            margin-right: 12px;

            &::after{
              content: '';
              position: absolute;
              top: 50%;
              left: 100%;
              transform: translateY(-50%);
            display: inline-block;
            opacity: 0.5;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #135EFF;
            font-size: 0;
            line-height: 0;
            transform: rotate(90deg);
            }

            
          }
          
        }
      }
      }
      
    }
    .EmployeeInfo{
      padding:18px 17px 12px 17px;
      .EmployeeInfotop{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .box{
          padding: 2px 0;
          width: 30%;
          display: flex;
          flex-direction: column;
          align-content: center;
          background: #FFFFFF;
          box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
          border-radius: 7px;
          .num{
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1A7CFA;
            margin-bottom: 3px;
          }
          .title{
            text-align: center;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #464B64;
          }
        }
      }
      ::v-deep.el-table {
      font-size: 12px;
      .el-table__cell {
        padding: 0;
      }
      th.el-table__cell {
        background: rgb(240, 240, 240);
        color: #333;
      }
      .cell {
        padding: 0;
        text-align: center;
      }
    }
    }
    }
    
   }
    
  }

  .roomContent{
    width: 100%;
    // height: 175px;
    background-color: #3087f7;
   .hotelTit{
    position: relative;
    margin-bottom: 15px;
    .titleBar{
    background: url('~@/assets/nb/roomTit.png');
    background-size: contain;
    height: 36px;
      width: 100%;
      .tit{
        display: block;
        width: 100%;
        font-size: 18px;
      font-weight: bold;
      color: #E5F6FF;
      text-align: center;
      text-shadow: 0px 2px 3px rgba(4,94,211,0.6);
      }
    }
    .active{
      background: url('~@/assets/nb/activebc.png') no-repeat;
      background-size: 100%;
      width: 100%;
      height: 100%;
      .bcInfo{
       padding: 15px 25px 5px 25px;
       .bctop{
        width: 100%;
        height: 135px;
        img{
          height: 100%;
        }
       }
       .text{
        margin-top: 9px;
        font-size: 12px;
        font-weight: 400;
        color: #464B64;
        line-height: 16px;
        text-align: justify;
       }
       
      }
     .cupInfo{
      padding: 15px 25px 5px 25px;
      .cuptop{
        width: 100%;
        height: 112px;
        img{
          height: 100%;
        }
       }
       .text{
        margin-top: 9px;
        font-size: 12px;
        font-weight: 400;
        color: #464B64;
        line-height: 16px;
        text-align: justify;
       }
    }
    .jjInfo{
      padding: 15px 25px 5px 25px;
      .jjtop{
        width: 100%;
        height: 91px;
        img{
          height: 100%;
        }
       }
       .text{
        margin-top: 9px;
        font-size: 12px;
        font-weight: 400;
        color: #464B64;
        line-height: 16px;
        text-align: justify;
       }
    
    }
    }
    .activeroomClearn{
      background: url('~@/assets/nb/activebajie.png') no-repeat;
      background-size: 100%;
      width: 100%;
      height: 100%;
      .roomInfo{
        padding: 15px 17px 0 17px;
       .search{
        height: 25px;
        padding: 0 35px;
        display: flex;
        align-items: center;
        justify-content:space-between;
        margin-bottom: 10px;
        .left{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #1492FF;
          span{
          display: block;
          width: 17px;
          height: 17px;
        background: url('~@/assets/nb/search.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        }
        input{
          width: 170px;
          border: none;
          outline: none;
          background-color: transparent;
        }
        }
        .right{
          width: 40px;
          height: 20px;
          background: #1492FF;
          box-shadow: 0px 0px 16px 0px rgba(190,204,194,0.3);
          border-radius: 22px;
          color: #fff;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
        }
      

        }
       .item{
        p {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        i {
          display: block;
          width: 5px;
          height: 14px;
          background: #236df5;
          border-radius: 5px;
          margin-right: 4px;
        }
        
      }
      .clean{
        display: block;
        margin: 10px 0;
        padding-left: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #464B64;
      }
      ::v-deep.el-table {
      font-size: 12px;
      .el-table__cell {
        padding: 0;
      }
      th.el-table__cell {
        background: rgb(240, 240, 240);
        color: #333;
      }
      .cell {
        padding: 0;
        text-align: center;
      }
    }
      
      }
      }
    }
   }
    
  }
}
.tabbar{
  z-index: 1;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  // margin: 0 20px;
  // margin: 0 20px !important;
  display: flex;
  justify-content: space-evenly;
  background-color:#4795fa;
  // background-color:rgba(255, 255, 255, 0.192);
  border-radius: 5px;
  font-size: 16px;
  padding: 8px 0;
  color: #CACACA;
  .tab{
  .el-message{
top: 80 !important
}
    display: flex;
    flex-direction: column;
    justify-content: center;
  .tabname{
    display: block;
    margin-top: 5px;
  }
  .tabnamehome{
    color: #fff;
  }
  .tabnameroom{
    color: #fff;
  }
  .tabnamepool{
    color: #fff;
  }
  .iconhome{
    display: block;
    background: url('~@/assets/nb/home.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: 17px 19px;
    background-position: 50% 50%;
  }
  .iconhomeactive{
    display: block;
    background: url('~@/assets/nb/activeHome.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size:100%;
  }
  .iconroom{
    display: block;
    background: url('~@/assets/nb/room.png')no-repeat;
    width: 36px;
    height: 36px;
    background-size: 20px 14px;
    background-position: 50% 50%;
  } 
  .iconroomactive{
    display: block;
    background: url('~@/assets/nb/activeRoom.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: 100%;
  }
  .iconpool{
    display: block;
    background: url('~@/assets/nb/pool.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: 20px 14px;
    background-position: 50% 50%;
  }
  }

}
.bottom{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: url('~@/assets/nb/bbanner.png') no-repeat;
  background-size: contain;
}
}

</style>
